import http from "../../hoc/axiosClient";
import { API_ENDPOINTS } from "../../appConfig";
import {
  SET_HOURSLOGGED_DATA,
  SET_HOURSLOGGED_DATA_ERR,
  SET_RES_HOURSLOGGED_DATA,
  SET_RES_HOURSLOGGED_DATA_ERR,
  SET_MODAL_RES_DATA,
  SET_MODAL_RES_ERR,
  SET_RES_NAME,
  SET_RES_NAME_ERR,
  SET_BILL_HOUR,
  SET_BILL_HOUR_ERR,
  SET_DEL_RES,
  SET_DEL_RES_ERR,
  SET_APPROVE_RES,
  SET_APPROVE_RES_ERR,
} from "../type";

export const getHoursloggedData = (localDate, id) => {
  return async function getHoursloggedDataThunk(dispatch) {
    const requestUrl = `${API_ENDPOINTS.hourslogged}${localDate}&id=${id}`;
    try {
      const response = await http.get(requestUrl);

      dispatch({ type: SET_HOURSLOGGED_DATA, payload: response.data });
    } catch (err) {
      dispatch({ type: SET_HOURSLOGGED_DATA_ERR, payload: err });
    }
  };
};

export const getResourcesHoursloggedData = (
  webTrackerId,
  projectID,
  startDate
) => {
  return async function getResourcesHoursloggedDataThunk(dispatch) {
    const requestUrl = `${API_ENDPOINTS.resourceHoursLogged}project_id=${projectID}&webtracker_project_id=${webTrackerId}&date=${startDate}`;
    try {
      const response = await http.get(requestUrl);
      // console.log(
      //   response.data.result.sort((a, b) => b.statustoadd - a.statustoadd),
      //   "dataaaaaaaaaaaaaaaaaaaaaa"
      // );

      dispatch({
        type: SET_RES_HOURSLOGGED_DATA,
        payload: response.data.result.sort(
          (a, b) => b.statustoadd - a.statustoadd
        ),
      });
    } catch (err) {
      dispatch({ type: SET_RES_HOURSLOGGED_DATA_ERR, payload: err });
    }
  };
};

export const getModalResourcesData = (id) => {
  return async function getModalResourcesDataThunk(dispatch) {
    const requestUrl = `${API_ENDPOINTS.modalResources}`;
    try {
      const response = await http.get(requestUrl);

      dispatch({ type: SET_MODAL_RES_DATA, payload: response.data });
    } catch (err) {
      dispatch({ type: SET_MODAL_RES_ERR, payload: err });
    }
  };
};

export const updateResourceName = (user_id, pro_id) => {
  return async function updateResourceNameThunk(dispatch) {
    const requestUrl = `${API_ENDPOINTS.newResources}user_id=${user_id}&webtracker_project_id=${pro_id}`;
    try {
      const response = await http.put(requestUrl);

      dispatch({ type: SET_RES_NAME, payload: response.data.message });
    } catch (err) {
      dispatch({ type: SET_RES_NAME_ERR, payload: err });
    }
  };
};

export const updateBilledHour = (obj) => {
  return async function updateBilledHourThunk(dispatch) {
    let isNullish = false;
    isNullish = Object.values(obj).every((value) => {
      if (value === null) {
        return true;
      }
    });
    if (!isNullish) {
      const requestUrl = `${API_ENDPOINTS.billedHours}start_date=${obj.start_date}`;
      try {
        let data = {
          user_id: Number(obj.user_id),
          project_id: Number(obj.project_id),
          projectName: obj.projectName,
          logged_time: Number(obj.logged_time),
          billed_hours: Number(obj.billed_hours),
        };

        // const response = await axios.post(requestUrl, body);

        const response1 = await fetch(requestUrl, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            "X-Parse-Application-Id":
              "Zuwgx3kZm3oOLZi56uRzq9rJYfyohQ8u4B2mVX5J",
            "X-Parse-REST-API-Key": "B3UUNqFsT4NVQ3BXMXRnyLFkcvtDlzwIUITKxlWm",
            "X-Parse-Revocable-Session": 1,
          },
        });
        const response = await http.post(requestUrl, data);
        dispatch({ type: SET_BILL_HOUR, payload: response.data });
      } catch (err) {
        dispatch({ type: SET_BILL_HOUR_ERR, payload: err });
      }
    } else {
      console.log("Null founded in Object.");
    }
  };
};

//to delete
export const deleteResource = (userId, webtracker_project_id, project_id) => {
  return async function deleteResourceThunk(dispatch) {
    const requestUrl = `${API_ENDPOINTS.deleteResources}userId=${userId}&webtracker_project_id=${webtracker_project_id}&project_id=${project_id}`;
    try {
      const response = await http.delete(requestUrl);

      dispatch({ type: SET_DEL_RES, payload: response.data.message });
    } catch (err) {
      dispatch({ type: SET_DEL_RES_ERR, payload: err });
    }
  };
};

//to approve
export const approveResource = (id, userId, project_id, date) => {
  return async function approveResourceThunk(dispatch) {
    const requestUrl = `${API_ENDPOINTS.approveResources}user_id=${userId}&project_id=${project_id}&date=${date}`;
    try {
      const response = await http.post(requestUrl);

      if (response.data) {
        dispatch(getResourcesHoursloggedData(id, project_id, date));
      }
      dispatch({ type: SET_APPROVE_RES, payload: response.data });
    } catch (error) {
      dispatch({ type: SET_APPROVE_RES_ERR, payload: error });
    }
  };
};
