import http from "../../hoc/axiosClient";
import { API_ENDPOINTS } from "../../appConfig";
import { SET_TIMESHEET_DATA, SET_TIMESHEET_DATA_ERR } from "../type";
import {
  SETFALSE_TIMESHEET_TABLE_DATA,
  SETTRUE_TIMESHEET_TABLE_DATA,
  SET_FILTER_PROJECT_NAME,
  SET_FILTER_PROJECT_OWNER,
  SET_FILTER_ENGAGEMENT_TYPE,
  SET_FILTER_STATUS
} from "../type";

export const getTimesheetData = (localDate, id) => {
  return async function getTimesheetDataThunk(dispatch) {
    const requestUrl = `${API_ENDPOINTS.timesheet}${localDate}&id=${id}`;
    try {
      const response = await http.get(requestUrl);
      if (response.data.projects.length === 0) {
        dispatch({ type: SET_TIMESHEET_DATA, payload: null });
        dispatch({ type: SETFALSE_TIMESHEET_TABLE_DATA });
      } else {
        dispatch({ type: SET_TIMESHEET_DATA, payload: response.data });
        dispatch({ type: SETTRUE_TIMESHEET_TABLE_DATA });
      }
      dispatch({ type: SET_FILTER_PROJECT_NAME, payload: '' });
      dispatch({ type: SET_FILTER_PROJECT_OWNER, payload: '' });
      dispatch({ type: SET_FILTER_ENGAGEMENT_TYPE, payload: '' });
      dispatch({ type: SET_FILTER_STATUS, payload: '' });
    } catch (err) {
      dispatch({ type: SET_TIMESHEET_DATA, payload: null });
      dispatch({ type: SET_TIMESHEET_DATA_ERR, payload: err });
      dispatch({ type: SETFALSE_TIMESHEET_TABLE_DATA });
    }
  };
};

export const clearFilterData = () => {
  return async function clearFilterDataThunk(dispatch) {
    dispatch({ type: SET_FILTER_PROJECT_NAME, payload: '' });
    dispatch({ type: SET_FILTER_PROJECT_OWNER, payload: '' });
    dispatch({ type: SET_FILTER_ENGAGEMENT_TYPE, payload: '' });
    dispatch({ type: SET_FILTER_STATUS, payload: '' });
  };
};
