const DownArrow = () => {
  return (
    <>
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.00081 5.99999L0.757812 1.75699L2.17281 0.342987L5.00081 3.17199L7.82881 0.342987L9.24381 1.75699L5.00081 5.99999Z"
          fill="#1F4173"
        />
      </svg>
    </>
  );
};

export default DownArrow;
