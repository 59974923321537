const hostname = window.location.hostname;
let BASE_URL;

if (hostname === "localhost") {
  BASE_URL = "http://localhost:3501";
} else {
  BASE_URL = "https://stageapp.api.classicinformatics.net";
}
// BASE_URL = "https://stageapp.api.classicinformatics.net";

const API_PREFIX = `${BASE_URL}/api`;

export const apiUrls = {
  timesheet: `${API_PREFIX}/projects/timesheet?date=`,
  dashboard: `${API_PREFIX}/dashboard/activeProject?`,
  engagementTypes: `${API_PREFIX}/projects/engagementTypes`,
  hourslogged: `${API_PREFIX}/hourslog/hourslog?date=`,
  resourceHoursLogged: `${API_PREFIX}/hourslog/monthlyData?`,
  modalResources: `${API_PREFIX}/hourslog/hourslog/getUsers`,
  newResources: `${API_PREFIX}/hourslog/hourslog/addUser?`,
  billedHours: `${API_PREFIX}/hourslog/hourslog/addBilledHours?`,
  deleteResources: `${API_PREFIX}/hourslog/hourslog/deleteResource?`,
  approveResources: `${API_PREFIX}/hourslog/hourslog/addResource?`,
  timesheetResource: `${API_PREFIX}/projects/allresource?webtracker_project_id=`,
  timesheetDetailedResource: `${API_PREFIX}/projects/detailedResource?`,
  timesheetFilterReducer: `${API_PREFIX}/projects/timeSheet?`,
  excelSheetData: `${API_PREFIX}/projects/getExcelSheet`,
};
