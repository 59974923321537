import React from "react";
import gridColumns from "./gridColumns";
import { useSelector } from "react-redux";
import TableHorizontalLine from "./tableHorizontalLine";
import TableStyledDataComponents from "./TableStyledDataComponents";
import TablePagination from "../../commonComponents/TablePagination/tablePagination";
import "./timesheetTable.css";

const TempTable = (props) => {
  const girdColumnsSize = gridColumns(props.tableCols.length);
  const tableColumns = props.tableCols;
  const columnStyleHead = {
    display: "grid",
    gridTemplateColumns: girdColumnsSize,
    borderRadius: "15px",
    height:
      props.tableHeadingHeight !== undefined
        ? props.tableHeadingHeight
        : "auto",
  };
  const columnStyleBody = {
    display: "grid",
    gridTemplateColumns: girdColumnsSize,
  };

  const tableDataComponents = [
    "ProjectHealth",
    "HoursLogged",
    "Members",
    "BilledHours",
    "Status",
  ];

  const currentPage = useSelector((state) => state.paginationStates.activePage);
  const dataPerPage = useSelector(
    (state) => state.paginationStates.dataPerPage
  );

  if (props.locCurrentPage !== undefined) {
    const indexOfLastData = props.locCurrentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage - dataPerPage;
    var currentData = props.tableData.slice(indexOfFirstData, indexOfLastData);
  } else {
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    var currentData = props.tableData.slice(indexOfFirstData, indexOfLastData);
  }

  return (
    <>
      <div
        style={
          props.tableHeight !== undefined ? { height: props.tableHeight } : null
        }
        className="table-container-wrapper"
      >
        <div className="timesheet-table-container">
          <div style={columnStyleHead} className="timesheet-table-head">
            {tableColumns.map((element, index) => {
              return (
                <>
                  <div
                    style={
                      index === 0
                        ? { paddingLeft: "20px", marginRight: "26px" }
                        : index === tableColumns.length - 1
                        ? {
                            marginLeft: "20px",
                          }
                        : null
                    }
                    className="table-head-data"
                  >
                    {element.columnName}
                  </div>
                </>
              );
            })}
          </div>
          <TableHorizontalLine />

          {currentData.length === 0 ? (
            <div className="table-body-no-data">No Data Found</div>
          ) : (
            <div className="timesheet-table-body">
              {currentData.map((element) => {
                return (
                  <>
                    <div
                      style={columnStyleBody}
                      className="timesheet-table-body-row"
                    >
                      {Object.entries(element).map((ele, index) => {
                        if (index > 0) {
                          return (
                            <>
                              {tableDataComponents.includes(ele[0]) ? (
                                <div
                                  id={
                                    element[
                                      tableColumns[index - 1].columnKeyValue
                                    ]
                                  }
                                  onClick={tableColumns[index - 1].keyFunction}
                                  style={
                                    index === 1
                                      ? {
                                          paddingLeft: "20px",
                                          fontSize: "13px",
                                        }
                                      : { fontSize: "13px" }
                                  }
                                  className="table-body-data"
                                >
                                  <TableStyledDataComponents
                                    styledComponent={ele[0]}
                                    styledComponentData={ele[1]}
                                  />
                                </div>
                              ) : (
                                <div
                                  id={
                                    element[
                                      tableColumns[index - 1].columnKeyValue
                                    ]
                                  }
                                  onClick={tableColumns[index - 1].keyFunction}
                                  style={
                                    index === 1
                                      ? {
                                          paddingLeft: "20px",
                                          marginRight: "26px",
                                          fontSize: "13px",
                                        }
                                      : {
                                          fontSize: "13px",
                                        }
                                  }
                                  className={
                                    tableColumns[index - 1].keyFunction ===
                                    undefined
                                      ? "table-body-data"
                                      : "table-body-data-clickable"
                                  }
                                >
                                  {ele[1] === null || ele[1] === " "
                                    ? "N/A"
                                    : ele[1]}
                                </div>
                              )}
                            </>
                          );
                        }
                      })}
                    </div>
                    <TableHorizontalLine />
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {window.location.pathname !== "/dashboard" ? (
        <TablePagination
          dataLength={props.tableData.length}
          dataLimit={10}
          pageLimit={3}
          localPaddingBottom={props.locPaddingBottom}
          localChangePage={props.locChangePage}
          localGoToPrevPage={props.locgotoPrevPage}
          localGoToNextPage={props.locgotoNextPage}
          localCurrentPage={props.locCurrentPage}
          currentPage={props.currentPage}
          setCurrentPage={props.setCurrentPage}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default TempTable;
