import { SET_ENGAGEMENT_TYPES_DATA, SET_ENGAGEMENT_TYPES_DATA_ERR } from "../type";

const initialState = {
  engagementTypeData: null,
  engagementTypeDataErr: null,
};

const engagementTypeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ENGAGEMENT_TYPES_DATA:
      return { ...state, engagementTypeData: payload };
    case SET_ENGAGEMENT_TYPES_DATA_ERR:
      return { ...state, engagementTypeDataErr: payload };
    default:
      return { ...state };
  }
};

export default engagementTypeReducer;
