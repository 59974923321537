import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Store from "../../../redux/store";
import * as syncActions from "../../../actions/syncActions";

import { useState, useEffect } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { RiCalendar2Line } from "react-icons/ri";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./ProjectComponent.css";
import { Modal } from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";
import { Input } from "antd";
import { AiFillCheckCircle } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  getResourcesHoursloggedData,
  getModalResourcesData,
  updateResourceName,
  updateBilledHour,
  deleteResource,
  approveResource,
  getHoursloggedData,
} from "../../../redux/actions/hoursloggedAction";
import {
  setSwitchDeactive,
  setSwitchActive,
} from "../../../redux/actions/timesheetFilterSwitch";
import { convertToHours } from "../../../utils";

let totalHoursLogged = 0;
const currentDate = new Date();

const ProjectComponent = (props) => {
  const [show, setShow] = useState();
  const [currDate, setCurrDate] = useState(props.date ? props.date : currentDate);
  const [newObj, setObj] = useState({});
  const [rTableData, setRTData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [newResId, setID] = useState();
  const [hovwr, setHovwr] = useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [billedHour, setBilledHour] = useState(0);
  const [objBilledHour, setObjBL] = useState({
    project_id: "",
    start_date: "",
    projectName: "",
    user_id: "",
    logged_time: "",
    billed_hours: "",
  });
  
  const [checkBox, setCheckBox] = useState(false);
  const [selectedArray, setselectedArray] = useState([]);
  // const [checkBilledHoursArray, setCheckBilledHoursArray] = useState([]);
  // const [checkBilledHourValue, setBilledHourValue] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const [selectedObj, setSelectedObj] = useState([]);

  const project_id = props.id;

  const dispatch = useDispatch();

  const hoursLoggedModuleData = useSelector(
    (state) => state.hoursLogged.hoursloggedData
  );

  const modalResourcesData = useSelector(
    (state) => state.hoursLogged.modalResData
  );

  const resHoursLoggedTableData = useSelector((state) => {
    return state.hoursLogged.resHoursLoggedData;
  });

  //to get week

  const weekIs = useSelector((state) => {
    return state.hoursLogged.billHour?.weekIs;
  });

  //login use id
  const loginUserId = useSelector((state) => state.user.userDetails.id);

  //runs first and to get existed data
  useEffect(async () => {
    await dispatch(setSwitchActive(true));

    dispatch(
      getResourcesHoursloggedData(
        props.id,
        props.projectID,
        moment(currDate).format("YYYY-MM-DD")
      )
    );
  }, [currDate]);

  //to get total hourslogged
  if (rTableData) {
    let sum = 0;
    rTableData.forEach((element) => {
      if (element.logged_time != null) {
        sum = sum + element.logged_time/60;
      }
    });
    totalHoursLogged = sum.toFixed(2);
  }

  // for table data and runs after update
  useEffect(() => {
    if (resHoursLoggedTableData !== null) {
      setRTData(resHoursLoggedTableData);
    }
  }, [resHoursLoggedTableData]);

  //this is for head data
  useEffect(() => {
    if (hoursLoggedModuleData !== null) {
      hoursLoggedModuleData.projects.find((obj) => {
        if (obj.webtracker_project_id == props.id) {
          setObj(obj);
        }
      });
    }
  }, [hoursLoggedModuleData]);

  //for modal
  useEffect(() => {
    if (modalResourcesData !== null) {
      setModalData(modalResourcesData);
    }
  }, [modalResourcesData]);

  const handleShow = () => {
    setShow(true);
    dispatch(getModalResourcesData());
  };

  //to close
  const handleClose = () => {
    setShow(false);
  };

  const backToHoursLogged = () => {
    props.onClick(false);
    dispatch(setSwitchDeactive(false));
  };

  const ResourcesHandler = (e) => {
    setID(e.target.value);
  };

  const updateResourcesHandler = async () => {
    const obj = {
      webID: props.id,
      proID: props.projectID,
      date: moment(currDate).format("YYYY-MM-DD"),
    };
    if (newResId) {
      const existingMemberFound = rTableData.filter((item) => {
        if (item.user_id === newResId) {
          return true;
        }
      });
      if(existingMemberFound.length > 0) {
        alert(existingMemberFound[0].member_name+" is already an existing resource");
      } else {
        await dispatch(updateResourceName(newResId, project_id, obj)); //calling PUT method to update from Modal

        dispatch(
          getResourcesHoursloggedData(
            props.id,
            props.projectID,
            moment(currDate).format("YYYY-MM-DD")
          )
        );
        setShow(false);
        setID("");
      }
    }
  };

  //to update billed hour
  const handleBlur = async (event, value) => {
    Store.dispatch(syncActions.Spinner(true));
    await dispatch(updateBilledHour(objBilledHour));
    dispatch(
      getResourcesHoursloggedData(
        props.id,
        props.projectID,
        moment(currDate).format("YYYY-MM-DD")
      )
    );
    dispatch(
      getHoursloggedData(moment(currDate).format("YYYY-MM-DD"), loginUserId)
    );

    const filterTableBH = rTableData.filter((item) => {
      if (item.user_id === value.user_id) {
        item.billed_hours = Number(billedHour);
      }
      return item;
    });

    const filterTableBHValue = selectedObj.filter((item) => {
      if (item.user_id === value.user_id) {
        item.billed_hours = Number(billedHour);
      }
      return item;
    });
    setSelectedObj(filterTableBHValue);

    setRTData(filterTableBH);
    setSelectRow(null);
    setBilledHour(null);
    
    Store.dispatch(syncActions.Spinner(false));
  };

  const deleteHandler = async (element) => {
    await dispatch(deleteResource(element.user_id, project_id, props.projectID));
    dispatch(
      getResourcesHoursloggedData(
        props.id,
        props.projectID,
        moment(currDate).format("YYYY-MM-DD")
      )
    );
    props.onClick(true);
  };

  //for checkbox

  const handleAllCheck = (e) => {
    setIsChecked(!isChecked);
    if (e.target.checked) {
      let arr = [];
      let arrObj = [];
      rTableData.forEach((element) => {
        arr.push(element.user_id);
        arrObj.push(element);
      });
      setselectedArray(arr);
      setSelectedObj(arrObj);
    } else {
      setselectedArray([]);
      setSelectedObj([]);
    }
  };

  const handleOnCheckboxChange = (event, value) => {
    const { user_id, billed_hours, project_id } = value;

    if (event.target.checked) {
      setselectedArray([...selectedArray, event.target.value]);

      const filterBillHour = rTableData.filter((item) =>
        selectedArray.map((element) => {
          if (item.user_id === element) {
            return item.billed_hours;
          }
        })
      );


      setSelectedObj([
        ...selectedObj,
        {
          user_id,
          billed_hours,
          project_id: props.projectID,
        },
      ]);
    } else {
      const filtered = selectedArray.filter(
        (item) => item !== event.target.value
      );

      const filterObj = selectedObj.filter((item) => item.user_id !== user_id);
      setselectedArray(filtered);
      setSelectedObj(filterObj);
    }
  };

  //to approve

  const approveHandler = () => {
    let flag = false;
    const filterValue = selectedObj.map((element) => {
      if (element.billed_hours === null) {
        flag = true;
      } else {
        dispatch(
          approveResource(
            props.id,
            element.user_id,
            element.project_id,
            moment(currDate).format("YYYY-MM-DD")
          )
        );
        setselectedArray([]);
        setSelectedObj([]);
      }
      return element;
    });
    if(flag) alert("Please add billed hours before approve.");
  };
  const montlyBackHandler = () => {
    let prevDate = Date.parse(
      new Date(
        currDate.getFullYear(),
        currDate.getMonth() - 1,
        currDate.getDate()
      )
    );

    setCurrDate(new Date(prevDate));
  };

  const monthFrontHandler = () => {
    let nextDate = Date.parse(
      new Date(
        currDate.getFullYear(),
        currDate.getMonth() + 1,
        currDate.getDate()
      )
    );
    setCurrDate(new Date(nextDate));
  };

  const validateBilledHours = (txt, evt) => {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 46) {
      //Check if the text already contains the . character
      if (txt.indexOf('.') === -1) {
        return true;
      } else {
        return false;
      }
    } else {
      if (charCode > 31 &&
        (charCode < 48 || charCode > 57))
        return false;
    }
    return true;
  }

  return (
    <>
      {" "}
      <Modal
        size="sm"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <div className="resModal">
          <div className="resModalHead">
            <h5>Add New Resources</h5>
          </div>
          <div className="resModalBody">
            <h6>New Resources</h6>
            <div>
              {/* <input className="resModalInput" /> */}
              <select id="" className="modalSelect" onChange={ResourcesHandler}>
                <option selected>Resources</option>
                {modalData.result
                  ? modalData.result.map((element, index) => {
                      return (
                        <option value={element.id} key={index}>
                          {element.name}
                        </option>
                      );
                    })
                  : ""}
              </select>
              <div className="resModalBtn">
                <button onClick={handleClose}>Cancel</button>
                <button onClick={updateResourcesHandler}>Add</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div style={{ marginRight: "20px" }}>
        <p className="backBtn" onClick={backToHoursLogged}>
          <FaAngleLeft /> Back Hour Logged
        </p>
        <h3 className="heading">{newObj.project_name}</h3>
        <div className="headBox" style={{ margin: "10px 0 10px 0" }}>
          <div className="headDiv">
            Project Owner: <span>{newObj.project_owner}</span>
          </div>
          <div className="headDiv">
            Account Code: <span>{newObj.account_code}</span>
          </div>
          <div className="headDiv">
            Engagement Type: <span>{newObj.engagement_type}</span>
          </div>
          <div className="headDiv">
            {/* Hours Logged: <span>{newObj.hours_logged}</span> */}
            Hours Logged: <span>{totalHoursLogged}</span>
          </div>
          <div className="headDiv">
            Project Code: <span>{newObj.project_code}</span>
          </div>
        </div>
        <div>
          <table cellSpacing={0} className="loggedHoursResourcesTable">
            {/* <thead> */}
            <tr>
              <th colSpan={4} style={{ textAlign: "center" }}>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    alignItems: "center",
                    background: "rgb(250 250 250)",
                    height: "80px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <FaAngleLeft
                      className="captionLeftArrow"
                      onClick={montlyBackHandler}
                    />
                    <span className="tableDate">
                      Monthly Hours Logged{" : "}
                      {moment(currDate).format("MMM YYYY")}
                    </span>
                    <FaAngleRight
                      className="captionLeftArrow"
                      onClick={monthFrontHandler}
                    />
                  </div>
                  <div>
                    {currentDate.getMonth() !==
                    currDate.getMonth() ? (
                      ""
                    ) : (
                      <button className="addResourcesBtn" onClick={handleShow}>
                        + Add Resources
                      </button>
                    )}
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th className="loggedHours_tdata">
                {" "}
                {rTableData && rTableData.length === 0 ? (
                  <input
                    type="checkbox"
                    name="allCheck"
                    style={{ margin: "0px 15px" }}
                    className="campaign-checkbox"
                    disabled
                  />
                ) : (
                  <input
                    type="checkbox"
                    name="allCheck"
                    style={{ margin: "0px 15px" }}
                    checked={
                      rTableData && selectedArray.length !== rTableData.length
                        ? false
                        : true
                    }
                    onChange={handleAllCheck}
                    className="campaign-checkbox"
                  />
                )}
                Resource
              </th>
              <th className="loggedHours_tdata">Hours logged</th>
              <th className="loggedHours_tdata">Billed hours</th>
              <th className="loggedHours_tdata" style={{ textAlign: "center" }}>
                Status
              </th>
            </tr>
            {/* </thead> */}
            <tbody style={{ height: "100px", overflowY: "auto" }}>
              {rTableData && rTableData.length === 0 ? (
                <tr>
                  <td
                    style={{
                      color: "#1f4173",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                    colspan={4}
                  >
                    No record found
                  </td>
                </tr>
              ) : (
                rTableData &&
                rTableData.map((element, i) => {
                  return (
                    <tr className="projectCompTr" key={"item" + i}>
                      <td
                        className="loggedHours_tdata "
                        style={{ fontWeight: "500" }}
                      >
                        <input
                          type="checkbox"
                          name={element.billed_hours}
                          value={element.user_id}
                          checked={
                            selectedArray &&
                            selectedArray.filter((it) => it === element.user_id)
                              .length > 0
                              ? true
                              : false
                          }
                          // checked={true}
                          onChange={(e) => handleOnCheckboxChange(e, element)}
                          style={{ margin: "0px 15px" }}
                          onClick={() => {
                            setCheckBox(!checkBox);
                            setCheckBox(i);
                            // setBilledHourValue(element.billed_hours);
                          }}
                          //checked={checkindex == i ? checkBox : false}
                        />
                        {element.member_name}{" "}
                        {element.statustoadd == 0 ? (
                          <span className="resourceSpan">NEW</span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="loggedHours_tdata">
                        <div className="centerPadding">
                          {element.logged_time
                            ? convertToHours(element.logged_time)
                            : 0}
                        </div>
                      </td>
                      <td
                        className="loggedHours_tdata"
                      >
                        <div>
                          {selectRow == i ? (
                            //******************Edit false******************
                            <Input
                              className="loggedhourInput"
                              autoFocus
                              suffix={
                                <div>
                                  <AiFillCheckCircle
                                    color="green"
                                    onClick={(e) => handleBlur(e, element)}
                                  />
                                  <GiCancel
                                    onClick={() => {
                                      setSelectRow(null);
                                      setBilledHour(null);
                                    }}
                                  />
                                </div>
                              }
                              onKeyPress={(event) => {
                                if (!validateBilledHours(billedHour, event)) {
                                  event.preventDefault();
                                }
                              }}
                              value={billedHour}
                              onChange={(e) => {
                                setObjBL({
                                  ...objBilledHour,
                                  project_id: props.projectID,
                                  start_date:
                                    moment(currDate).format("YYYY-MM-DD"),
                                  projectName: props.projectName,
                                  user_id: element.user_id,
                                  logged_time:
                                    element.logged_time != null
                                      ? element.logged_time
                                      : 0,
                                  billed_hours: e.target.value,
                                });
                                setBilledHour(e.target.value);
                              }}
                            />
                          ) : (
                            // **************** Edit true ********************
                            <Input
                              readOnly
                              className="loggedhourInput"
                              suffix={
                                element.week_status == 0 ? (
                                  <AiOutlineEdit
                                    onClick={() => {
                                      setSelectRow(i);
                                      setBilledHour(element.billed_hours !== null
                                        ? element.billed_hours
                                        : "");
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  ""
                                )
                              }
                              onKeyPress={(event) => {                                
                                if (!validateBilledHours(billedHour, event)) {
                                  event.preventDefault();
                                }
                              }}
                              value={
                                element.billed_hours !== null
                                  ? element.billed_hours
                                  : ""
                              }
                              onChange={(e) => {
                                setObjBL({
                                  ...objBilledHour,
                                  project_id: props.projectID,
                                  start_date:
                                    moment(currDate).format("YYYY-MM-DD"),
                                  projectName: props.projectName,
                                  user_id: element.user_id,
                                  logged_time:
                                    element.logged_time != null
                                      ? element.logged_time
                                      : 0,
                                  billed_hours: e.target.value,
                                });
                                setBilledHour(e.target.value);
                              }}
                            />
                          )}
                        </div>
                      </td>
                      <td
                        className="loggedHours_tdata loggedStatus"
                        onMouseEnter={() => {
                          setHovwr(i);
                        }}
                        onMouseLeave={() => {
                          setHovwr(null);
                        }}
                        onClick={() => deleteHandler(element)}
                      >
                        {hovwr == i &&
                        element.statustoadd == 0 &&
                        element.week_status == 0 ? (
                          <RiDeleteBinLine className="deleteBtn" />
                        ) : (
                          <div
                            className={`centerPadding  ${
                              element.week_status == 1 ? "green" : "orange"
                            }`}
                          >
                            {element.week_status == 1 ? "Approved" : "Pending"}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
            <tfoot>
              <tr
                style={{
                  background: "#FAFAFA",
                  boxShadow: "inset 0px 1px 4px rgba(0, 0, 0, 0.07)",
                  borderRadius: "0px 0px 15px 15px",
                  overflow: "hidden",
                }}
              >
                <td colSpan={4}>
                  {selectedArray.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        padding: "25px",
                      }}
                      className="lastBtn"
                    >
                      <button
                        onClick={() => {
                          setselectedArray([]);
                          setSelectedObj([]);
                        }}
                      >
                        Cancel
                      </button>
                      <button onClick={approveHandler}>Approve</button>
                    </div>
                  ) : null}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default ProjectComponent;

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <div>
      <label onClick={props.onClick} ref={ref}>
        {props.value || props.placeholder}
      </label>
      <RiCalendar2Line onClick={props.onClick} className="tableCalender" />
    </div>
  );
});
