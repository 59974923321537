const TableHorizontalLine = () => {
    return(
        <>
        <div style={{width: '100%',paddingLeft:'14px', paddingRight:'14px'}}>
            <div style={{width: '100%', height:"1px", backgroundColor:"#1F4173", opacity:"0.07"}}>

            </div>
        </div>
        </>
    )
}

export default TableHorizontalLine