import React from "react";
import { useState } from "react";
import { RiFilterOffFill } from "react-icons/ri";
import { Button, Tooltip } from "antd";
import moment from "moment";
import {
  setSwitchActive,
  setSwitchDeactive,
} from "../../../redux/actions/timesheetFilterSwitch";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTimesheetFilterData,
  getHoursLoggedFilterData,
} from "../../../redux/actions/timesheetFilterAction";
import { getTimesheetData, clearFilterData } from "../../../redux/actions/timesheetActions";
import { getHoursloggedData } from "../../../redux/actions/hoursloggedAction";
import { convertToHours } from "../../../utils";
import { excelSheetAction } from "../../../redux/actions/timesheetFilterAction";
import { message } from "antd";
import * as XLSX from "xlsx";
import "./timesheetFilter.css";
import { setActivePage } from "../../../redux/actions/paginationActions";

const TimesheetFilters = (props) => {
  const selectedProjectName = useSelector((state) => state.timesheetFilter.filterProjectName);
  const selectedProjectOwner = useSelector((state) => state.timesheetFilter.filterProjectOwner);
  const selectedEngegementType = useSelector((state) => state.timesheetFilter.filterEngegementType);
  const selectedStatus = useSelector((state) => state.timesheetFilter.filterStatus);

  const dispatch = useDispatch();
  
  const [checked, setChecked] = useState(false);
  const [filterProjectName, setFilterProjectName] = useState(selectedProjectName);
  const [filterProjectOwner, setFilterProjectOwner] = useState(selectedProjectOwner);
  const [filterEngagementType, setFilterEngagementType] = useState(selectedEngegementType);
  const [filterStatus, setFilterStatus] = useState(selectedStatus);
  const [sheetData, setSheetData] = useState([]);
  const [filterEnabled, setFilterEnabled] = useState(false);
  
  const loginUserId = useSelector((state) => state.user.userDetails.id);

  // for timesheet
  const cardsDisplayAction = useSelector(
    (state) => state.timesheet.timesheetData
  );

  // for hourslogged
  const cardsDisplayActionHoursLog = useSelector(
    (state) => state.hoursLogged.hoursloggedData
  );

  //for excel sheet
  const excelSheetData = useSelector(
    (state) => state.excelSheetData.excelSheet
  );

  const engagementTypeData = useSelector(
    (state) => state.engagementType.engagementTypeData
  );

  const roleID = useSelector((state) => {
    if (window.location.pathname === "/timesheet") {
      return state.timesheet.timesheetData?.roleId;
    } else if (window.location.pathname === "/hours-logged") {
      return state.hoursLogged.hoursloggedData?.roleId;
    }
  });

  useEffect(() => {
    filterApiCall();
  }, [props.date, filterEnabled]);

  useEffect(() => {
    const filterData = [];
    excelSheetData?.forEach((ele) => {
      const {
        project_name,
        id,
        project_code,
        account_code,
        code,
        logged_time,
        billed_hours,
        owner_name
      } = ele;
      filterData.push({
        Projects: project_name,
        ProjectId: id,
        ProjectCode: project_code,
        AccountCode: account_code,
        ResourceCode: code != null ? code : "",
        // EngagementType: engagement_type,
        OwnerName: owner_name,
        HoursLogged: convertToHours(logged_time),
        BilledHours: billed_hours,
        // Status: status,
      });
    });
    setSheetData(filterData);
  }, [excelSheetData]);

  const downloadLeads = (timeshhetDataList, excelFileName) => {
    let workBook = XLSX.utils.book_new();
    let workSheet = XLSX.utils.json_to_sheet(timeshhetDataList);
    XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet 1");
    XLSX.writeFile(workBook, `${excelFileName}.xlsx`);
  };

  const exportDataToExcel = () => {
    const fileName =
      window.location.pathname == "/timesheet"
        ? "Timesheet Resource"
        : "HoursLogged Resource";

    downloadLeads(sheetData, fileName);
    message.success("Download Successful");
    setSheetData(null);
  };

  const filterDate = moment(props.date).format("YYYY-MM-DD");
  const changeProjectName = (event) => {
    event.preventDefault();
    setFilterProjectName(event.target.value);
  };

  const changeProjectOwner = (event) => {
    event.preventDefault();
    setFilterProjectOwner(event.target.value);
  };

  const changeEngagementType = (event) => {
    event.preventDefault();
    setFilterEngagementType(event.target.value);
  };
  const changeStatus = (event) => {
    event.preventDefault();
    setFilterStatus(event.target.value);
  };

  const filterApiCall = () => {
    if (window.location.pathname === "/timesheet") {
      dispatch(
        getTimesheetFilterData(
          filterProjectName,
          filterProjectOwner,
          filterEngagementType,
          filterStatus,
          props.date,
          loginUserId
        )
      );
    } else if (window.location.pathname === "/hours-logged") {
      dispatch(
        getHoursLoggedFilterData(
          filterProjectName,
          filterProjectOwner,
          filterEngagementType,
          filterStatus,
          filterDate,
          loginUserId
        )
      );
    }
    setFilterEnabled(true);
    props.setCurrentPage(1);
    dispatch(excelSheetAction(
      moment(props.date).format("YYYY-MM-DD"),
      loginUserId,
      filterProjectName,
      filterProjectOwner,
      filterEngagementType,
      filterStatus
    ));
    dispatch(setActivePage(1));
    dispatch(setSwitchDeactive(false));
  };

  const clearFilter = async () => {
    setFilterProjectName("");
    setFilterProjectOwner("");
    setFilterEngagementType("");
    setFilterStatus("");
    dispatch(setSwitchDeactive(false));
    dispatch(clearFilterData());
    if (window.location.pathname === "/timesheet") {
      await dispatch(getTimesheetData(filterDate, loginUserId));
    } else {
      await dispatch(getHoursloggedData(filterDate, loginUserId));
    }
  };
  const handleChange = (event) => {
    checked === true
      ? dispatch(setSwitchActive(event.target.checked))
      : dispatch(setSwitchDeactive(event.target.checked));
    setChecked(event.target.checked);
  };
  useEffect(() => {
    checked === true
      ? dispatch(setSwitchActive(checked))
      : dispatch(setSwitchDeactive(checked));
  }, []);

  return (
    <>
      <div className="filterBy">
        <p className="filter-by">Filter By</p>
      </div>
      <div className="horizontal-slidder">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="project-name-tab">
            <input
              value={filterProjectName}
              className="project-name"
              placeholder="Project Name"
              onChange={changeProjectName}
            />
          </div>
          {/* at 1 we will show the field and at 2 we will hide,
          here we are getting 2 from backend which means we needs to hide the field*/}
          {/* {window.location.pathname === "/hours-logged" ? ( */}
          {
            roleID !== 2 ? (
              <div className="project-owner-tab">
                <input
                  value={filterProjectOwner}
                  className="project-owner"
                  placeholder="Project Owner"
                  onChange={changeProjectOwner}
                />
              </div>
            ) : (
              ""
            )
          }
          <div className="project-engagement-tab">
            <select className="project-engagement" onChange={changeEngagementType} style={{ color : (filterEngagementType==='') ? '#7e8fa7' : ''}}>
              <option value="" hidden>Engagement Type</option>
              {engagementTypeData && engagementTypeData.length>0 && engagementTypeData.map((item, index) => (
                <option value={(item['engagement_type'] === 'T&M') ? 'temporary' : item['engagement_type']} selected={(item['engagement_type']===filterEngagementType) ? "selected" : ""}>{item['engagement_type']}</option>
              ))}
            </select>
          </div>
          <div className="project-status-tab">
            <select className="project-status" onChange={changeStatus} style={{ color : (filterStatus==='') ? '#7e8fa7' : ''}}>
              <option value="" hidden>Status</option>
              <option value='0' selected={(filterStatus===0 || filterStatus==="0") ? "selected" : ""}>Pending</option>
              <option value='1' selected={(filterStatus===1 || filterStatus==="1") ? "selected" : ""}>Approved</option>
            </select>
          </div>
          <div className="buttonGo">
            <button className="button-go" onClick={() => filterApiCall()}>
              {" "}
              Go
            </button>
          </div>
          <div className="buttonGo">
            <Tooltip placement="top" title={"Clear all Filter"}>
              <Button
                className="button-clear"
                type="primary"
                onClick={clearFilter}
                shape="circle"
              >
                <RiFilterOffFill />
              </Button>
            </Tooltip>
          </div>

          <Tooltip placement="top" title={"Download Resource"}>
            {cardsDisplayAction !== null ||
            cardsDisplayActionHoursLog !== null ? (
              <>
                <button
                  style={{ float: "right", marginTop: "1px" }}
                  className="export-to-excel"
                  onClick={exportDataToExcel}
                  // onClick={() => dispatch(excelSheetAction())}
                >
                  {" "}
                  Export to Excel
                </button>{" "}
              </>
            ) : (
              <>
                <button
                  style={{
                    float: "right",
                    marginTop: "1px",
                    cursor: "not-allowed",
                  }}
                  className="disable-export-to-excel"
                >
                  {" "}
                  Export to Excel
                </button>{" "}
              </>
            )}
          </Tooltip>
        </div>
      </div>
    </>
  );
};
export default TimesheetFilters;
