import React from 'react'

const Userprofile = () => {
    return (
        <>

            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.50472 8.9203C3.81529 8.9203 3.05176e-05 12.7356 3.05176e-05 17.425C3.05176e-05 17.6291 0.165699 17.7948 0.369812 17.7948H16.6397C16.8438 17.7948 17.0094 17.6291 17.0094 17.425C17.0094 12.7356 13.1941 8.9203 8.50472 8.9203Z" fill="#3C3C3C"></path><path d="M8.50468 8.13486C10.7511 8.13486 12.5721 6.3138 12.5721 4.06741C12.5721 1.82102 10.7511 -4.57764e-05 8.50468 -4.57764e-05C6.25829 -4.57764e-05 4.43723 1.82102 4.43723 4.06741C4.43723 6.3138 6.25829 8.13486 8.50468 8.13486Z" fill="#3C3C3C"></path></svg>

        </>
    )
}

export default Userprofile;