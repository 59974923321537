import http from "../../hoc/axiosClient";
import { API_ENDPOINTS } from "../../appConfig";
import {
  SET_TIMESHEET_DATA,
  SET_TIMESHEET_DATA_ERR,
  DISPLAY_TIMESHEET_FILTER_DATA,
  SET_HOURSLOGGED_DATA,
  SET_HOURSLOGGED_DATA_ERR,
  SET_EXCELSHEET_DATA,
  SETFALSE_TIMESHEET_TABLE_DATA,
  SETTRUE_TIMESHEET_TABLE_DATA,
  SET_FILTER_PROJECT_NAME,
  SET_FILTER_PROJECT_OWNER,
  SET_FILTER_ENGAGEMENT_TYPE,
  SET_FILTER_STATUS
} from "../type";

export const getTimesheetFilterData = (
  projectName,
  projectOwner,
  engagementType,
  status,
  filterDate,
  id
) => {
  return async function getTimesheetFilterDataThunk(dispatch) {
    let requestUrl = `${
      API_ENDPOINTS.timesheetFilterReducer
    }date=${filterDate}&id=${id}&project_owner_id=${id}`;
    if(projectName) {      
      dispatch({ type: SET_FILTER_PROJECT_NAME, payload: projectName });
      requestUrl+=`&project_name=${projectName}`;
    }
    if(projectOwner) {
      dispatch({ type: SET_FILTER_PROJECT_OWNER, payload: projectOwner });
      requestUrl+=`&project_owner=${projectOwner}`;
    }
    if(engagementType) {
      dispatch({ type: SET_FILTER_ENGAGEMENT_TYPE, payload: engagementType });
      requestUrl+=`&engagement_type=${engagementType}`;
    }
    if(status) {
      dispatch({ type: SET_FILTER_STATUS, payload: status });
      requestUrl+=`&status=${status}`;
    }
    try {
      const response = await http.get(requestUrl);

      if (response.data.projects.length>0 && response.data.projects[0].length === 0) {
        dispatch({ type: SETFALSE_TIMESHEET_TABLE_DATA });
        dispatch({ type: SET_TIMESHEET_DATA, payload: null });
      } else {
        dispatch({
          type: SET_TIMESHEET_DATA,
          payload: response.data,
        });
        dispatch({ type: SETTRUE_TIMESHEET_TABLE_DATA });
      }
    } catch (error) {
      dispatch({ type: SET_TIMESHEET_DATA_ERR, payload: error });
      dispatch({ type: SETFALSE_TIMESHEET_TABLE_DATA });
      dispatch({ type: SET_TIMESHEET_DATA, payload: null });
    }
  };
};
//for hourslogged

export const getHoursLoggedFilterData = (
  projectName,
  projectOwner,
  engagementType,
  status,
  filterDate,
  id
) => {
  return async function getHoursLoggedFilterDataThunk(dispatch) {
    let requestUrl = `${
      API_ENDPOINTS.hourslogged
    }${filterDate}&id=${id}&project_owner_id=${id}`;
    if(projectName) {      
      dispatch({ type: SET_FILTER_PROJECT_NAME, payload: projectName });
      requestUrl+=`&project_name=${projectName}`;
    }
    if(projectOwner) {
      dispatch({ type: SET_FILTER_PROJECT_OWNER, payload: projectOwner });
      requestUrl+=`&project_owner=${projectOwner}`;
    }
    if(engagementType) {
      dispatch({ type: SET_FILTER_ENGAGEMENT_TYPE, payload: engagementType });
      requestUrl+=`&engagement_type=${engagementType}`;
    }
    if(status) {
      dispatch({ type: SET_FILTER_STATUS, payload: status });
      requestUrl+=`&status=${status}`;
    }
    try {
      const response = await http.get(requestUrl);
      if (response.data.projects.length>0 && response.data.projects[0].length === 0) {
        dispatch({ type: SETFALSE_TIMESHEET_TABLE_DATA });
      } else {
        dispatch({
          type: SET_HOURSLOGGED_DATA,
          payload: response.data,
        });
        dispatch({ type: SETTRUE_TIMESHEET_TABLE_DATA });
      }
    } catch (error) {
      dispatch({ type: SET_HOURSLOGGED_DATA_ERR, payload: error });
      dispatch({ type: SETFALSE_TIMESHEET_TABLE_DATA });
    }
  };
};

export const cardsDisplayAction = (cards) => {
  return async (dispatch) => {
    await dispatch({ type: DISPLAY_TIMESHEET_FILTER_DATA, payload: cards });
  };
};

export const excelSheetAction = (date, id, projectName, projectOwner, engagementType) => {
  return async function excelSheetActionThunk(dispatch) {
    let requestUrl = `${API_ENDPOINTS.excelSheetData}?date=${date}&id=${id}`;
    if(projectName) {
      requestUrl+=`&project_name=${projectName}`;
    }
    if(projectOwner) {
      requestUrl+=`&project_owner=${projectOwner}`;
    }
    if(engagementType) {
      requestUrl+=`&engagement_type=${engagementType}`;
    }
    try {
      const response = await http.get(requestUrl);

      dispatch({
        type: SET_EXCELSHEET_DATA,
        payload: response.data.result,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
