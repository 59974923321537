import React from 'react';

const NavbarLogo = () => {
    return(
        <>
        <svg width="247" height="26" viewBox="0 0 247 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginLeft:"30px"}}>
<path d="M18.8996 0.390472H15.7734V25.6091H18.8996V0.390472Z" fill="#006DA8"/>
<path d="M42.2384 23.6556C40.6753 23.6556 39.0411 23.016 38.082 22.4479L37.3359 24.7921C38.5438 25.5025 40.2845 25.9997 42.1673 25.9997C46.1819 25.9997 48.5621 23.8687 48.5621 20.8141C48.5621 18.2565 46.999 16.7648 44.0504 15.6637C41.8123 14.7758 40.8174 14.1362 40.8174 12.7155C40.8174 11.4368 41.8121 10.3713 43.6596 10.3713C45.2583 10.3713 46.5016 10.9751 47.1766 11.4013L47.9226 9.12813C46.999 8.52433 45.4714 8.06259 43.7307 8.06259C40.0714 8.06259 37.7978 10.3357 37.7978 13.1062C37.7978 15.1662 39.2543 16.8358 42.3807 17.9724C44.6543 18.8248 45.5425 19.6062 45.5425 21.098C45.5425 22.5545 44.4767 23.6556 42.2384 23.6556Z" fill="#006DA8"/>
<path d="M21.4199 21.0627C21.4199 23.549 23.1962 26 26.6779 26C29.1291 26 30.9767 24.7924 31.9358 23.4427H32.0424L32.2911 25.6093H35.0975C34.9199 24.4372 34.8489 22.9807 34.8489 21.4889V15.0599C34.8489 11.6502 33.57 8.06259 28.312 8.06259C26.145 8.06259 24.0488 8.6664 22.6278 9.55435L23.3383 11.6499C24.5461 10.833 26.2158 10.3713 27.8499 10.3713C31.3669 10.3357 31.7579 12.9286 31.7579 14.3138V14.6689C25.1145 14.6337 21.4199 16.9069 21.4199 21.0627ZM31.829 16.8361V19.8196C31.829 20.1037 31.7935 20.4589 31.6869 20.8141C31.1895 22.2703 29.733 23.6911 27.4594 23.6911C25.8963 23.6911 24.5464 22.7673 24.5464 20.7073C24.5461 17.3686 28.4186 16.7648 31.829 16.8361Z" fill="#006DA8"/>
<path d="M65.4363 1.63385C64.264 1.63385 63.4824 2.5218 63.4824 3.58734C63.4824 4.65288 64.2284 5.50532 65.3655 5.50532C66.6089 5.50532 67.3549 4.65288 67.3549 3.58734C67.3549 2.48628 66.5731 1.63385 65.4363 1.63385Z" fill="#006DA8"/>
<path d="M54.92 23.6556C53.3569 23.6556 51.7228 23.016 50.7636 22.4479L50.0176 24.7921C51.2254 25.5025 52.9661 25.9997 54.8492 25.9997C58.8635 25.9997 61.2437 23.8687 61.2437 20.8141C61.2437 18.2565 59.6806 16.7648 56.732 15.6637C54.494 14.7758 53.499 14.1362 53.499 12.7155C53.499 11.4368 54.4937 10.3713 56.3413 10.3713C57.9399 10.3713 59.1833 10.9751 59.8582 11.4013L60.6043 9.12813C59.6806 8.52433 58.153 8.06259 56.4123 8.06259C52.753 8.06259 50.4794 10.3357 50.4794 13.1062C50.4794 15.1662 51.9359 16.8358 55.0624 17.9724C57.336 18.8248 58.2241 19.6062 58.2241 21.098C58.2238 22.5545 57.1581 23.6556 54.92 23.6556Z" fill="#006DA8"/>
<path d="M9.23647 10.5489C10.9417 10.5489 12.114 10.9751 12.8603 11.3658L13.5708 8.98606C12.7182 8.52433 11.084 8.06259 9.23674 8.06259C3.62381 8.06259 0 11.9343 0 17.2265C0 22.4835 3.37513 25.9997 8.56176 25.9997C10.8709 25.9997 12.6829 25.3959 13.5 25.0052L12.9671 22.661C12.079 23.0517 10.9067 23.478 9.13016 23.478C5.71977 23.478 3.16198 21.0272 3.16198 17.0489C3.16171 13.4969 5.29321 10.5489 9.23647 10.5489Z" fill="#006DA8"/>
<path d="M66.9992 8.41806H63.873V25.6093H66.9992V8.41806Z" fill="#006DA8"/>
<path d="M78.7931 10.5489C80.4983 10.5489 81.6706 10.9751 82.4169 11.3658L83.1274 8.98606C82.2748 8.52433 80.6407 8.06259 78.7934 8.06259C73.1805 8.06259 69.5566 11.9343 69.5566 17.2265C69.5566 22.4835 72.9318 25.9997 78.1184 25.9997C80.4275 25.9997 82.2396 25.3959 83.0566 25.0052L82.5238 22.661C81.6356 23.0517 80.4633 23.478 78.6868 23.478C75.2764 23.478 72.7186 21.0272 72.7186 17.0489C72.7184 13.4969 74.8498 10.5489 78.7931 10.5489Z" fill="#006DA8"/>
<path d="M87.3892 0.710052C85.6485 0.710052 84.5117 1.88215 84.5117 3.40942C84.5117 4.90118 85.613 6.1088 87.3182 6.1088C89.1299 6.1088 90.2312 4.90118 90.2312 3.40942C90.196 1.88215 89.1302 0.710052 87.3892 0.710052Z" fill="#B22326"/>
<path d="M90.0541 8.24017H84.6543V25.609H90.0541V8.24017Z" fill="#B22326"/>
<path d="M109.486 25.609V15.3441C109.486 10.2295 106.821 7.84949 103.268 7.84949C100.355 7.84949 98.6147 9.51883 97.9039 10.6554H97.7973L97.5487 8.24019H92.8594C92.9304 9.80298 93.0015 11.6147 93.0015 13.7813V25.609H98.4013V15.593C98.4013 15.0954 98.4368 14.5985 98.5789 14.243C98.9696 13.2485 99.8578 12.2185 101.35 12.2185C103.304 12.2185 104.085 13.746 104.085 15.9834V25.609H109.486Z" fill="#B22326"/>
<path d="M115.242 1.74038C113.714 3.16111 113.075 5.46978 113.075 7.56534V8.24019H110.766V12.2182H113.075V25.6091H118.475V12.2185H121.921V8.24019H118.439V7.35224C118.439 5.64737 119.185 4.26217 120.961 4.26217C121.672 4.26217 122.24 4.36872 122.702 4.51079L122.915 0.355181C122.169 0.17759 121.245 1.17458e-07 120.073 1.17458e-07C118.475 -0.000268959 116.627 0.461734 115.242 1.74038Z" fill="#B22326"/>
<path d="M130.801 25.9997C135.454 25.9997 139.966 23.0873 139.966 16.7293C139.966 11.4723 136.414 7.81396 131.12 7.81396C125.507 7.81396 121.812 11.4013 121.812 17.0489C121.812 22.661 125.72 25.9997 130.801 25.9997ZM130.943 11.6857C133.394 11.6857 134.389 14.314 134.389 16.8716C134.389 20.0327 133.074 22.1283 130.943 22.1283C128.634 22.1283 127.39 19.8906 127.39 16.9071C127.39 14.3496 128.385 11.6857 130.943 11.6857Z" fill="#B22326"/>
<path d="M147.284 25.609V16.8003C147.284 16.3741 147.32 15.9479 147.391 15.593C147.746 13.9233 149.096 12.8578 151.085 12.8578C151.689 12.8578 152.116 12.9291 152.542 12.9999V7.92052C152.151 7.84948 151.903 7.81396 151.405 7.81396C149.7 7.81396 147.604 8.87951 146.716 11.4368H146.574L146.36 8.24018H141.742C141.849 9.73194 141.884 11.4013 141.884 13.9589V25.609H147.284Z" fill="#B22326"/>
<path d="M180.286 25.6091V15.3799C180.286 10.1584 177.764 7.81424 174.424 7.81424C173.145 7.81424 172.08 8.1339 171.121 8.66667C170.268 9.16393 169.522 9.87429 168.847 10.7978H168.776C168.03 8.98633 166.289 7.81424 164.016 7.81424C161.067 7.81424 159.504 9.44807 158.758 10.5846H158.651L158.438 8.24046H153.855C153.927 9.80325 153.997 11.6149 153.997 13.7815V25.6093H159.255V15.4861C159.255 15.0244 159.291 14.5271 159.468 14.1009C159.788 13.2485 160.605 12.183 161.991 12.183C163.732 12.183 164.513 13.6747 164.513 15.8416V25.6093H169.771V15.4506C169.771 14.9889 169.842 14.4561 169.949 14.0654C170.339 13.0354 171.192 12.183 172.471 12.183C174.211 12.183 175.029 13.6392 175.029 16.161V25.6091H180.286Z" fill="#B22326"/>
<path d="M182.027 20.672C182.027 23.4427 184.123 26 187.64 26C189.701 26 191.477 25.2541 192.614 23.8689H192.72L193.04 25.6093H197.907C197.694 24.6503 197.623 23.052 197.623 21.4182V15.3441C197.623 11.224 195.811 7.81424 190.02 7.81424C186.858 7.81424 184.478 8.70219 183.27 9.37703L184.265 12.8578C185.402 12.1474 187.285 11.5436 189.061 11.5436C191.725 11.5436 192.223 12.8578 192.223 13.8168V14.0299C186.077 14.0299 182.027 16.161 182.027 20.672ZM192.401 17.4041V19.2866C192.401 19.6062 192.365 19.9262 192.294 20.2103C191.939 21.3114 190.802 22.1993 189.488 22.1993C188.28 22.1993 187.356 21.5245 187.356 20.1395C187.356 18.0792 189.559 17.4041 192.401 17.4041Z" fill="#B22326"/>
<path d="M217.302 8.24017H211.902V25.609H217.302V8.24017Z" fill="#B22326"/>
<path d="M200.997 5.00806V8.2402H198.688V12.2182H200.997V19.3221C200.997 21.7726 201.494 23.4425 202.489 24.4725C203.377 25.3604 204.834 25.9642 206.574 25.9642C208.066 25.9642 209.381 25.7511 210.056 25.5022L210.02 21.4177C209.523 21.5242 209.168 21.5595 208.422 21.5595C206.823 21.5595 206.29 20.6007 206.29 18.5049V12.2185H210.163V8.2402H206.29V3.55182L200.997 5.00806Z" fill="#B22326"/>
<path d="M214.637 0.710052C212.896 0.710052 211.76 1.88215 211.76 3.40942C211.76 4.90118 212.861 6.1088 214.566 6.1088C216.378 6.1088 217.479 4.90118 217.479 3.40942C217.444 1.88215 216.378 0.710052 214.637 0.710052Z" fill="#B22326"/>
<path d="M229.452 12.0054C230.873 12.0054 231.832 12.254 232.472 12.5381L233.324 8.52433C232.187 8.09811 230.624 7.84949 229.203 7.84949C222.738 7.84949 219.256 12.0051 219.256 17.0844C219.256 22.5187 222.844 25.9642 228.457 25.9642C230.517 25.9642 232.329 25.609 233.288 25.1473L232.649 21.1693C231.832 21.5242 230.837 21.7373 229.558 21.7373C226.929 21.7373 224.798 20.0324 224.798 16.8713C224.762 14.0299 226.61 12.0054 229.452 12.0054Z" fill="#B22326"/>
<path d="M242.31 14.9889C240.179 14.243 239.504 13.8168 239.504 12.9644C239.504 12.1119 240.25 11.5792 241.564 11.5792C243.021 11.5792 244.548 12.1474 245.33 12.5737L246.254 8.84426C245.188 8.31149 243.412 7.81424 241.387 7.81424C237.088 7.81424 234.317 10.265 234.317 13.5329C234.281 15.593 235.667 17.5465 239.29 18.7541C241.28 19.4289 241.813 19.8551 241.813 20.7786C241.813 21.6665 241.138 22.199 239.504 22.199C237.905 22.199 235.844 21.5242 234.85 20.9204L233.891 24.7566C235.205 25.467 237.23 25.9997 239.504 25.9997C244.477 25.9997 247 23.6198 247 20.3524C246.964 17.8303 245.614 16.1255 242.31 14.9889Z" fill="#B22326"/>
</svg>

        </>
    )
}

export default NavbarLogo