import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { BiChevronDown } from "react-icons/bi";
import { RiCalendar2Line } from "react-icons/ri";
import timesheetLayoutTemplate from "../../layouts/timesheetLayout/timesheetLayoutTemplate";
import TimesheetFilters from "../../commonComponents/timesheetFilters/timesheetFilters";
import BackArrow from "../../../assets/images/icons/BackArrow";
import { GiCancel } from "react-icons/gi";
import TimesheetTable from "../../commonComponents/TimesheetTable/TimesheetTable";
import "./TimesheetModule.css";
import DateFilter from "../../commonComponents/DateFilterComponent/DateFilter";
import ModalTimesheet from "../../commonComponents/Modal/ModalTimesheet";
import { useDispatch, useSelector } from "react-redux";
import { setModalActive } from "../../../redux/actions/modalAction";
import { getEnagegmentTypeData } from "../../../redux/actions/engagementActions";
import { setFilterDate } from "../../../redux/actions/dateFilterActions";
import { convertToHours } from "../../../utils";
import moment from "moment";
import {
  getTimesheetResourceData,
  getParticularResourceData,
  setResourceName,
} from "../../../redux/actions/timesheetResourceAction";
import { setActivePage } from "../../../redux/actions/paginationActions";
import {
  setSwitchDeactive,
  setSwitchActive,
} from "../../../redux/actions/timesheetFilterSwitch";
import "./TimesheetModule.css";

const Timesheet = (props) => {
  const [timesheetFilterData, setTimesheetFilterData] = useState(null);
  const [projectNameHeading, setProjectNameHeading] = useState(null);
  const [timesheetResources, setTimesheetResources] = useState();
  const [filterDataMini, setFilterDataMini] = useState(null);
  const [webtrackerId, setWebtrackerId] = useState(" ");
  const [resTableActivePage, setResTableActivePage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [date, setDate] = useState(new Date());

  const timesheetModuleRoleID = useSelector(
    (state) => state.timesheet.timesheetData?.roleId
  );

  const timesheetModuleData = useSelector(
    (state) => state.timesheet.timesheetData
  );
  const timesheetStartDate = useSelector(
    (state) => state.dateFilter.filterDateStart
  );

  const timesheetResourceeSwitch = useSelector(
    (state) => state.timesheetFilterSwitch.showSwitchTab
  );

  const filterDate = moment(timesheetStartDate).format("YYYY-MM-DD");

  const timesheetEndDate = useSelector(
    (state) => state.dateFilter.filterDateEnd
  );

  const dispatch = useDispatch();

  const timesheetResourceData = useSelector(
    (state) => state.timesheetResource.timesheetResourceData
  );
  // pagination functions

  const resGotoNextPage = () => {
    setResTableActivePage((page) => page + 1);
  };

  const resgotoPrevPage = () => {
    setResTableActivePage((page) => page - 1);
  };

  const resChangePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    setResTableActivePage(pageNumber);
  };

  const setResourcesData = (event) => {
    setProjectNameHeading(event.target.innerText);
    setWebtrackerId(event.target.id);
    dispatch(
      getTimesheetResourceData(
        event.target.id,
        timesheetStartDate,
        timesheetEndDate
      )
    );
    dispatch(setSwitchActive(true));
  };

  useEffect(async () => {
    var dateDashboard = moment(date);
    var monthDashboard = parseInt(dateDashboard.month());
    var yearDashboard = dateDashboard.year();
    const lastDateMonth = moment(
      new Date(yearDashboard, monthDashboard + 1, 0)
    ).format("DD");
    if (monthDashboard < 10) {
      const startDate = `${yearDashboard}-0${monthDashboard + 1}-01`;
      const endDate = `${yearDashboard}-0${
        monthDashboard + 1
      }-${lastDateMonth}`;
      dispatch(setFilterDate(startDate, endDate));
    } else {
      const startDate = `${yearDashboard}-${monthDashboard + 1}-01`;
      const endDate = `${yearDashboard}-${monthDashboard + 1}-${lastDateMonth}`;
      dispatch(setFilterDate(startDate, endDate));
    }
  }, [date]);

  const setResourceDetailedData = (event) => {
    const userID = event.target.id;
    dispatch(setResourceName(event.target.innerText));
    dispatch(
      getParticularResourceData(
        webtrackerId,
        timesheetStartDate,
        timesheetEndDate,
        userID
      )
    );
    dispatch(setModalActive());
  };

  const TimesheetSwitchCols = [
    {
      columnName: "Projects",
      columnKeyValue: "WebTrackerId",
      keyFunction: setResourcesData,
    },

    timesheetModuleData && timesheetModuleRoleID != 2
      ? {
          columnName: "Project Owner",
        }
      : "",
  ];
  const TimesheetSwitchCols2 = [
    {
      columnName: "Resource",
      columnKeyValue: "UserId",
      keyFunction: setResourceDetailedData,
    },

    {
      columnName: "Hours Logged",
    },
  ];
  const TimesheetModalCols = [
    {
      columnName: "Projects",
      columnKeyValue: "WebTrackerId",
      keyFunction: setResourcesData,
    },

    timesheetModuleData && timesheetModuleRoleID != 2
      ? {
          columnName: "Project Owner",
        }
      : "",

    {
      columnName: "Engagement Type",
    },

    {
      columnName: "Hours Logged",
    },

    {
      columnName: "Billed Hours",
    },
  ];

  useEffect(() => {
    dispatch(setActivePage(1));
    dispatch(getEnagegmentTypeData());
  }, []);

  useEffect(() => {
    const filterData = [];
    if (timesheetResourceData.length >= 1) {
      timesheetResourceData.forEach((ele) => {
        filterData.push({
          ResourceName: ele.name,
          TimeLog: parseInt(ele.time_logged) / 60,
        });
      });
    }

    setTimesheetResources(filterData);
    return () => {};
  }, [timesheetResourceData]);

  const sortTimesheet = (a, b) => {
    const projectName1 = a.Projects.toLowerCase();
    const projectName2 = b.Projects.toLowerCase();
    let comparison = 0;
    if (projectName1 > projectName2) {
      comparison = 1;
    } else if (projectName1 < projectName2) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    const filterData = [];
    const filterDataMini = [];

    if (timesheetModuleData !== null) {
      timesheetModuleData.projects.forEach((ele) => {
        filterDataMini.push({
          WebTrackerId: ele.webtracker_project_id,
          Projects: ele.project_name,
          ProjectOwner: timesheetModuleRoleID != 2 ? ele.project_owner : "",
        });
        filterData.push({
          WebTrackerId: ele.webtracker_project_id,
          Projects: ele.project_name,
          ProjectOwner: timesheetModuleRoleID != 2 ? ele.project_owner : "",
          // ProjectCode: ele.project_code,
          // AccountCode: ele.account_code,
          EngagementType: ele.engagement_type,
          HoursLogged: convertToHours(ele.hours_logged),
          BilledHours: ele.billed_hours,
        });
      });
    }

    setTimesheetFilterData(filterData.sort(sortTimesheet));
    setFilterDataMini(filterDataMini.sort(sortTimesheet));
  }, [timesheetModuleData]);

  return (
    <>
      <div>
        <ModalTimesheet />
      </div>

      <div className="timesheet-container">
        <div className="timesheet-container-heading">
          <div className="timesheet-heading-title">
            <h3> Timesheet </h3>
          </div>
          <div className="timesheet-heading-date">
            <DatePicker
              selected={date}
              onChange={(d) => {
                setDate(d);
              }}
              dateFormat="MMM yyyy"
              showMonthYearPicker
              customInput={<CustomInput />}
            />
          </div>
        </div>
        <TimesheetFilters
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          date={filterDate}
        />
        <div>{""}</div>

        {timesheetResourceeSwitch && (
          <div
            onClick={() => {
              dispatch(setSwitchDeactive(false));
            }}
            className="timesheet-back-button"
          >
            <p className="back-to-dashboard">
              {" "}
              <span className="back-arrow">
                {" "}
                <BackArrow />{" "}
              </span>{" "}
              Back to Table
            </p>
          </div>
        )}

        <div
          className={
            window.location.pathname === "/timesheet"
              ? "table-container-custom"
              : "table-container"
          }
          style={
            timesheetResourceeSwitch === true
              ? { background: "#F5F7FB" }
              : { background: "#FFF" }
          }
        >
          {timesheetResourceeSwitch === false ? (
            timesheetFilterData !== null ? (
              <>
                <TimesheetTable
                  tableCols={TimesheetModalCols}
                  tableData={timesheetFilterData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </>
            ) : null
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "50%",
                    borderRadius: "15px",
                    margin: "3px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  {filterDataMini && (
                    <TimesheetTable
                      tableCols={TimesheetSwitchCols}
                      tableData={filterDataMini}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  )}
                </div>

                <div
                  style={{
                    width: "50%",
                    borderRadius: "15px",
                    margin: "3px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div className="table-project-name">
                    {projectNameHeading}
                    <span
                      style={{ float: "right" }}
                      className="styleDateIcons"
                      onClick={() => dispatch(setSwitchDeactive(false))}
                    >
                      <GiCancel />
                    </span>
                  </div>
                  {timesheetResourceData && timesheetResourceData.length > 0 ? (
                    <TimesheetTable
                      tableCols={TimesheetSwitchCols2}
                      tableData={timesheetResourceData}
                      locChangePage={resChangePage}
                      locgotoNextPage={resGotoNextPage}
                      locgotoPrevPage={resgotoPrevPage}
                      locCurrentPage={resTableActivePage}
                      tableHeight={"80%"}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  ) : (
                    <div class="no-resource-container">
                      No resources allocated yet!
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default timesheetLayoutTemplate(Timesheet);

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <div className="hoursLogCalender">
      <label onClick={props.onClick} ref={ref}>
        {props.value || props.placeholder}
      </label>
      <div onClick={props.onClick}>
        <RiCalendar2Line className="tableCalender" />
        <BiChevronDown style={{ fontSize: "20px", fontWeight: "bold" }} />
      </div>
    </div>
  );
});
