
const LoginStorageUserDetails= "user-classic"
const LoginDetilsExpiryTime= 172800
const InactiveToolsStorageName="inactive-tools"
const ActiveToolsStorageName = "activetools"

export {
    LoginStorageUserDetails,
    LoginDetilsExpiryTime,
    InactiveToolsStorageName,
    ActiveToolsStorageName
    }