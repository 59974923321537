import http from "../../hoc/axiosClient";
import { API_ENDPOINTS } from "../../appConfig";
import { SET_ENGAGEMENT_TYPES_DATA, SET_ENGAGEMENT_TYPES_DATA_ERR } from "../type";

export const getEnagegmentTypeData = (localDate, id) => {
  return async function getEnagegmentTypeDataThunk(dispatch) {
    const requestUrl = `${API_ENDPOINTS.engagementTypes}`;
    try {
      const response = await http.get(requestUrl);
      if (response.data.engagements.length === 0) {
        dispatch({ type: SET_ENGAGEMENT_TYPES_DATA, payload: null });
      } else {
        dispatch({ type: SET_ENGAGEMENT_TYPES_DATA, payload: response.data.engagements });
      }
    } catch (err) {
      dispatch({ type: SET_ENGAGEMENT_TYPES_DATA, payload: null });
      dispatch({ type: SET_ENGAGEMENT_TYPES_DATA_ERR, payload: err });
    }
  };
};