export const GET_TOTAL_COUNT = "GET_TOTAL_COUNT";
export const GET_DATA_PER_PAGE = " GET_DATA_PER_PAGE";
export const GET_ACTIVE_COUNT = "GET_ACTIVE_COUNT";

export const GET_MODAL_ACTIVE = "GET_MODAL_ACTIVE";
export const GET_MODAL_INACTIVE = "GET_MODAL_INACTIVE";

// export const SET_TIMESHEET_DATA = "SET_TIMESHEET_DATA";
// export const SET_TIMESHEET_DATA_ERR = "SET_TIMESHEET_DATA_ERR";

export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
export const SET_DASHBOARD_DATA_ERR = "SET_DASHBOARD_DATA_ERR";

export const SET_HOURSLOGGED_DATA = "SET_HOURSLOGGED_DATA";
export const SET_HOURSLOGGED_DATA_ERR = "SET_HOURSLOGGED_DATA_ERR";

export const SET_RES_HOURSLOGGED_DATA = "SET_RES_HOURSLOGGED_DATA";
export const SET_RES_HOURSLOGGED_DATA_ERR = "SET_RES_HOURSLOGGED_DATA_ERR";

export const SET_MODAL_RES_DATA = "SET_MODAL_RES_DATA";
export const SET_MODAL_RES_ERR = "SET_MODAL_RES_ERR";

export const SET_RES_NAME = "SET_MODAL_RES_DATA";
export const SET_RES_NAME_ERR = "SET_MODAL_RES_ERR";

export const SET_BILL_HOUR = "SET_BILL_HOUR";
export const SET_BILL_HOUR_ERR = "SET_BILL_HOUR_ERR";

export const SET_DEL_RES = "SET_BILL_HOUR";
export const SET_DEL_RES_ERR = "SET_BILL_HOUR_ERR";

export const SET_APPROVE_RES = "SET_APPROVE_RES";
export const SET_APPROVE_RES_ERR = "SET_APPROVE_RES_ERR";

export const SET_TIMESHEET_DATA = "SET_TIMESHEET_DATA";
export const SET_TIMESHEET_DATA_ERR = "SET_TIMESHEET_DATA_ERR";

export const SET_ENGAGEMENT_TYPES_DATA = "SET_ENGAGEMENT_TYPES_DATA";
export const SET_ENGAGEMENT_TYPES_DATA_ERR = "SET_ENGAGEMENT_TYPES_DATA";

export const SET_EXCELSHEET_DATA = "SET_EXCELSHEET_DATA";
export const SET_EXCELSHEET_DATA_ERR = "SET_EXCELSHEET_DATA_ERR";

export const SET_TIMESHEET_RESOURCE_DATA = "SET_RESOURCE_DATA";
export const SET_TIMESHEET_RESOURCE_DATA_ERR = "SET_RESOURCE_DATA_ERR";

export const SET_SIDEBAR_COLLAPS_ACTIVE = "SET_SIDEBAR_COLLAPS_ACTIVE";
export const SET_SIDEBAR_COLLAPS_DEACTIVE = "SET_SIDEBAR_COLLAPS_DEACTIVE";
export const SET_SIDEBAR_ITEM = "SET_SIDEBAR_ITEM";

export const SET_DATA_DATE_FILTER = "SET_DATA_DATE_FILTER";
export const SET_RESOURCE_SWITCH_ACTIVE = "SET_RESOURCE_SWITCH_ACTIVE";
export const SET_RESOURCE_SWITCH_DEACTIVE = "SET_RESOURCE_SWITCH_DEACTIVE";

export const GET_TIMESHEET_DETAILED_RESOURCE_DATA =
  "GET_TIMESHEET_DETAILED_RESOURCE_DATA";
export const GET_TIMESHEET_DETAILED_RESOURCE_DATA_ERR =
  "GET_TIMESHEET_DETAILED_RESOURCE_DATA_ERR";

export const RESOURCE_NAME_TIMELOGGED = "RESOURCE_NAME_TIMELOGGED";

export const SET_FILTER_PROJECT_NAME = "SET_FILTER_PROJECT_NAME";
export const SET_FILTER_PROJECT_OWNER = "SET_FILTER_PROJECT_OWNER";
export const SET_FILTER_ENGAGEMENT_TYPE = "SET_FILTER_ENGAGEMENT_TYPE";
export const SET_FILTER_STATUS = "SET_FILTER_STATUS";
export const SETTRUE_TIMESHEET_TABLE_DATA = "SETTRUE_TIMESHEET_TABLE_DATA";
export const SETFALSE_TIMESHEET_TABLE_DATA = "SETFALSE_TIMESHEET_TABLE_DATA";

export const DISPLAY_TIMESHEET_FILTER_DATA = "DISPLAY_TIMESHEET_FILTER_DATA";
