import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BackArrow from "../../../assets/images/icons/BackArrow";
import timesheetLayoutTemplate from "../../layouts/timesheetLayout/timesheetLayoutTemplate";
import "./hoursLogged.css";
import DatePicker from "react-datepicker";
import { RiCalendar2Line } from "react-icons/ri";
import { BiChevronDown } from "react-icons/bi";
import moment from "moment";
import ProjectComponent from "./ProjectComp";
import TimesheetTable from "../../commonComponents/TimesheetTable/TimesheetTable";
import { convertToHours } from "../../../utils"
import {
  getHoursloggedData,
  getResourcesHoursloggedData,
} from "../../../redux/actions/hoursloggedAction";
import {
  setSwitchDeactive,
  setSwitchActive,
} from "../../../redux/actions/timesheetFilterSwitch";

import TimesheetFilters from "../../commonComponents/timesheetFilters/timesheetFilters";
import { useHistory } from "react-router-dom";
import { getEnagegmentTypeData } from "../../../redux/actions/engagementActions";

const HoursLogged = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  //to show ProjectComponent
  const [hoursloggedResources, setHoursloggedResources] = useState(false);
  //to show 2nd screen
  const [tableData, setTableData] = useState(null);
  //date
  const [date, setDate] = useState(new Date());
  const [id, setId] = useState("");
  const [projectID, setProjectID] = useState("");
  const [projectName, setProjectName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const hoursLoggedModuleData = useSelector(
    (state) => state.hoursLogged.hoursloggedData
  );

  const roleID = useSelector(
    (state) => state.hoursLogged.hoursloggedData?.roleId
  );
  
  const ProjectComponentHandler = (event) => {
    hoursLoggedModuleData.projects.forEach((element) => {
      if (element.webtracker_project_id == event.target.id) {
        setProjectID(element.project_id);
        setProjectName(element.project_name);
      }
    });

    if (projectID) {
      dispatch(
        getResourcesHoursloggedData(
          event.target.id,
          projectID,
          moment(date).format("YYYY-MM-DD")
        )
      );
    }
    dispatch(setSwitchActive(true));

    setHoursloggedResources(true);
    setId(event.target.id);
  };

  const tableColArray = [
    {
      columnName: "Projects",
      columnKeyValue: "ProjectId",
      keyFunction: ProjectComponentHandler,
    },
    hoursLoggedModuleData && roleID != 2
      ? {
          columnName: "Project Owner",
        }
      : "",
    {
      columnName: "Engagement Type",
    },
    {
      columnName: "Hours Logged",
    },
    {
      columnName: "Billed Hours",
    },
    {
      columnName: "Status",
    },
  ];
  const loginUserId = useSelector((state) => state.user.userDetails.id);

  //dispatches API action
  useEffect(() => {
    dispatch(
      getHoursloggedData(moment(date).format("YYYY-MM-DD"), loginUserId)
    );
    dispatch(
      getEnagegmentTypeData()
    );
    dispatch(setSwitchDeactive(false));
  }, []);

  const sortTimesheet = (a, b) => {
    const projectName1 = a.Projects.toLowerCase();
    const projectName2 = b.Projects.toLowerCase();
    let comparison = 0;
    if (projectName1 > projectName2) {
      comparison = 1;
    } else if (projectName1 < projectName2) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    const filterData = [];
    if (hoursLoggedModuleData !== null) {
      hoursLoggedModuleData.projects.forEach((ele) => {
        filterData.push({
          ProjectId: ele.webtracker_project_id,
          Projects: ele.project_name,
          ProjectOwner: roleID != 2 ? ele.project_owner : "",
          EngagementType: ele.engagement_type,
          HoursLogged: convertToHours(ele.hours_logged),
          BilledHours: ele.billed_hours,
          Status: ele.my_status,
        });
      });
      setTableData(filterData.sort(sortTimesheet));
    }
  }, [hoursLoggedModuleData]);

  const backToDashboard = () => {
    history.push("/dashboard");
  };

  const showProjectComponent = useSelector(
    (state) => state.timesheetFilterSwitch.showSwitchTab
  );

  return (
    <>
      {showProjectComponent ? (
        <ProjectComponent
          id={id}
          projectID={projectID}
          projectName={projectName}
          hoursloggedResources={hoursloggedResources}
          onClick={setHoursloggedResources}
          date={date}
        />
      ) : (
        <>
          <div className="timesheet-container">
            <div className="timesheet-back-button">
              <p className="back-to-dashboard" onClick={backToDashboard}>
                {" "}
                <span className="back-arrow">
                  {" "}
                  <BackArrow />{" "}
                </span>{" "}
                Back Dashboard
              </p>
            </div>
            <div className="timesheet-container-heading">
              <div className="timesheet-heading-title">
                <h3> Hours Logged / Project </h3>
              </div>
              <div className="timesheet-heading-date">
                <DatePicker
                  selected={date}
                  onChange={(d) => {
                    setDate(d);
                  }}
                  dateFormat="MMM yyyy"
                  showMonthYearPicker
                  customInput={<CustomInput />}
                />
              </div>
            </div>
            <TimesheetFilters
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              date={date}
            />
            <div className="table-container">
              {tableData && (
                <TimesheetTable
                  tableCols={tableColArray}
                  tableData={tableData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default timesheetLayoutTemplate(HoursLogged);

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <div className="hoursLogCalender">
      <label onClick={props.onClick} ref={ref}>
        {props.value || props.placeholder}
      </label>
      <div onClick={props.onClick}>
        <RiCalendar2Line className="tableCalender" />
        <BiChevronDown style={{ fontSize: "20px", fontWeight: "bold" }} />
      </div>
    </div>
  );
});
