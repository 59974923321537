import { SET_EXCELSHEET_DATA } from "../type";

const initialState = {
  excelSheet: null,
};

export const excelSheetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EXCELSHEET_DATA:
      return { ...state, excelSheet: payload };

    default:
      return state;
  }
};
